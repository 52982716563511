.images {
  position: relative;
}

#current {
  opacity: 1;
  position: absolute;
  left: 0;
}

@media (prefers-reduced-motion: no-preference) {
  #current.animation {
    animation: disappear 1s ease-in;
  }
}

@media (max-width: 767px) {
  .images {
    border: 1vw solid rgb(167,117,48);
  }
  .images img {
    width: 98vw;
    height: auto;
  }
}
@media (min-width: 768px) {
  .images {
    border: 1vh solid rgb(167,117,48);
  }
  .images img {
    height: 98vh;
    width: auto;
  }
}

@keyframes disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
